var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-auth" }, [
    _c("section", { staticClass: "section--fullScreen section--flexCenter" }, [
      _c(
        "div",
        {
          staticClass: "container container--xs",
          staticStyle: { "padding-right": "14px", "padding-left": "14px" },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "login", name: "login" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.validateFields)
                          },
                        },
                      },
                      [
                        _c("h2", { staticClass: "form__title h-textCenter" }, [
                          _vm._v("Hello Admin"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "formGroup" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "field",
                                rules: { required: true, email: true },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.email,
                                              expression: "email",
                                            },
                                            {
                                              name: "focus",
                                              rawName: "v-focus",
                                            },
                                          ],
                                          staticClass:
                                            "formInput formInput--fullWidth",
                                          class: {
                                            hasError: errors.length > 0,
                                          },
                                          attrs: {
                                            name: "email",
                                            type: "email",
                                            placeholder: "Enter Email",
                                          },
                                          domProps: { value: _vm.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.email = $event.target.value
                                            },
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors.length > 0,
                                                expression: "errors.length > 0",
                                              },
                                            ],
                                            staticClass:
                                              "formInput__hint formInput__hint--error",
                                          },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "formGroup" },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "field",
                                rules: { required: true },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.password,
                                              expression: "password",
                                            },
                                          ],
                                          staticClass:
                                            "formInput formInput--fullWidth",
                                          class: {
                                            hasError: errors.length > 0,
                                          },
                                          attrs: {
                                            name: "password",
                                            "data-vv-delay": "1000",
                                            type: "password",
                                            placeholder: "Enter Password",
                                          },
                                          domProps: { value: _vm.password },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.password = $event.target.value
                                            },
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors.length > 0,
                                                expression: "errors.length > 0",
                                              },
                                            ],
                                            staticClass:
                                              "formInput__hint formInput__hint--error",
                                          },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.loginError
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "formInput__hint formInput__hint--error h-textCenter",
                              },
                              [_vm._v(_vm._s(_vm.loginError))]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "form__submit formGroup" }, [
                          _c(
                            "button",
                            { staticClass: "btn btn--primary btn--fullWidth" },
                            [_vm._v("Log in")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }